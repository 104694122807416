import React from "react"
import { graphql, Link  } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "../components/Elements/Img"
import SectionContainer from "../components/SectionContainer"
import VideoPlayer from "../components/VideoPlayer/index"
import { Container, Row, Col } from "../components/Grid"
import {
  SectionBody,
  AccentHeader,
  PageTitle,
} from "../components/Elements/index"

const WorkLink = styled(props => <Link {...props} />)`
  color: #414141;
  &:active {
    color: #414141;
  }

  &:visited {
    color: #414141;
  }
`

const RevomeMarginWrapper = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const Footer = styled.footer`
  background: #6a115a;
  display: flex;
  height: 120px;
  align-items: center;
`

const RevomeMarginImage = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const RevomeMarginVideo = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const VideoContainerFrame = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .gatsby-image-wrapper {
    margin: 0;
    width: 100%;
  }
  iframe {
    position: absolute;
    z-index: 999;
    bottom: 3%;
    width: 53% !important;
    height: 100% !important;
    margin-left: -1%;
  }
`

const pluvictoProfessionalDigital = ({ data }) => (
  <Layout>
    <ThemeProvider
      theme={{
        backgroundColor: "#FFFFFF",
        primaryColor: "#414141",
        dividerColor: "#1D4289;",
        invert: {
          backgroundColor: "#1D4289",
          dividerColor: "#ffffff;",
        },
      }}
    >
      <>
        <Seo title="PLUVICTO | PLUVICTO Consumer Digital" />
        <SectionContainer noBottomMargin>
          <Container>
            <AccentHeader size="large" background="#ffffff">
              <WorkLink to="/">
                  The Work
                </WorkLink>
            </AccentHeader>
            <div style={{ paddingBottom: "30px" }}>
            <PageTitle style={{ color: "#78c800" }}>Targeting PSMA+ mCRPC wherever it goes</PageTitle>
            </div>
            <Row>
              <Col md={4}>                
                <AccentHeader>The Problem</AccentHeader>                
                <SectionBody size="regular">
                  Over two-thirds of people with metastatic prostate cancer will not
survive five years from diagnosis. The need for novel treatments is
urgent and great. PLUVICTO is a first-in-class therapy that delivers
targeted radiation directly to PSMA+ cells anywhere in the body. Our
challenge was educating medical oncologists on the importance of
PSMA as a biomarker, the use of PSMA-PET imaging to identify PSMA+
patients, and the exciting new mechanism of action of PLUVICTO,
proven to extend survival and improve clinical outcomes.<br />
                  <br />                  
                </SectionBody>
              </Col>
              <Col md={4}>
                <AccentHeader></AccentHeader>
                <SectionBody size="regular">
                  <h1>Addressing an unmet need in progressing mCRPC</h1>
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <RevomeMarginImage>
            <Img fluid={data.image1.childImageSharp.fluid} />
          </RevomeMarginImage>
        </SectionContainer>
        

        <SectionContainer noBottomMargin noTopMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "30px" }}>
            <Row>
              <Col md={4}>                
                <AccentHeader>The Idea</AccentHeader>                
                <SectionBody size="regular">
                  We leveraged patient and clinical images to demonstrate the key
competitive differentiator for PLUVICTO—the ability to target PSMA+
metastases in not only the bones, but nodal and visceral metastases as
well. We then carried this differentiator through digital and print tactics
to raise awareness, challenge treatment paradigms, and establish a
new standard of care.<br />
                  <br />                  
                </SectionBody>
              </Col>
              <Col md={4}>
                <AccentHeader></AccentHeader>
                <SectionBody size="regular">
                  <h1>First, we had to differentiate PLUVICTO from the current standard of care</h1>
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>
        

        <SectionContainer noBottomMargin noTopMargin>
          <div style={{ backgroundColor: "#A2BCC6" }}>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px" }}>
              <RevomeMarginWrapper noTopMargin noBottomMargin>
                <VideoPlayer
                  poster={() => <Img fluid={data.videoThumbnail3.childImageSharp.fluid} />}
                  url="https://player.vimeo.com/video/792677015?h=520f364bf5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  height="100%"
                  width="100%"
                />
              </RevomeMarginWrapper>
          </Container>
          </div>
        </SectionContainer>

        <SectionContainer invert noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "30px", color: "#ffffff" }}>
            <Row>
              <Col md={8}>                                            
                <SectionBody size="regular">
                  <h2>Mechanism of Action Video</h2><br/>
                  Educating oncologists with varying degrees of knowledge on how PLUVICTO works was priority one. We worked with a CGI company to create a multipurpose video to demonstrate the value of PSMA as a diagnostic and therapeutic target, the importance of PSMA-PET imaging for patient selection, and the novel mechanism of action that targets PSMA and delivers DNA-breaking radiation directly to the cell.<br />
                  <br />                  
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>


        <SectionContainer noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", backgroundColor: "#ffffff", color: "#5C5C5C" }}>
            <Row>
              <Col md={8}>
                <AccentHeader>                  
                  <h2>The Execution</h2>
                </AccentHeader>
                <SectionBody size="regular">
                  We developed a suite of highly-targeted digital tactics to reach medical oncologists where they consume information and drive them to our HCP website. The website houses phase 3 trial data, downloadable resources, the MOA video, and more. Since PLUVICTO must be administered in a specialized facility, we worked with the client's digital team to add a treatment center locator tool to the HCP content management system to help doctors find a certified center near their patients.
                </SectionBody>
              </Col>              
            </Row>

            <Row>
              <Col md={1}></Col>
              <Col md={6}>
                <center>
                <h2 style={{ color: "#000000" }}>Reaching Medical Oncologists Where They Read</h2>
                <SectionBody size="regular">
                  Animated banners were used for both organic posts and paid ads across multiple social platforms to raise awareness and drive traffic to the website.
                </SectionBody>
                </center>
              </Col>
              <Col md={1}></Col>
            </Row>
          </Container>
        </SectionContainer>  

        <SectionContainer noTopMargin noBottomMargin>
          <Container style={{ backgroundColor: "#ffffff", color: "#5C5C5C", paddingBottom: "100px" }}>
            <Row>
              <Col md={4}>                
                <div className="video-adjustment">
                <RevomeMarginWrapper>
                <VideoPlayer
                  poster={() => <Img fluid={data.videoThumbnail1.childImageSharp.fluid} />}
                  url="https://player.vimeo.com/video/792685873?h=d12d081c78&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  height="656px"
                  width="100%"
                />
              </RevomeMarginWrapper>
              </div>
              </Col> 
              <Col md={4}>                
                <div className="video-adjustment">
                <RevomeMarginWrapper>
                <VideoPlayer
                  poster={() => <Img fluid={data.videoThumbnail2.childImageSharp.fluid} />}
                  url="https://player.vimeo.com/video/792684384?h=62bf5e2ba2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  height="656px"
                  width="100%"
                />
              </RevomeMarginWrapper>
              </div>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>

        <hr/>

        <SectionContainer noTopMargin noBottomMargin>
          <Container>
            <Row>
              <Col md={1}></Col>
              <Col md={6}>
                <center>
                <h2 style={{ color: "#000000" }}>Treatment Center Locator Tool</h2>
                <SectionBody size="regular">
                  Doctors can easily locate the certified PLUVICTO treatment center closest to their patients to simplify referrals and minimize travel burden.
                </SectionBody>
                </center>
              </Col>
              <Col md={1}></Col>
            </Row>
          </Container>
        </SectionContainer> 

        <SectionContainer noTopMargin>
          <Container>
            <RevomeMarginImage>
              <Img fluid={data.image2.childImageSharp.fluid} />
            </RevomeMarginImage>
          </Container>
        </SectionContainer>
         

        <SectionContainer noTopMargin noBottomMargin>
          <Container style={{ backgroundColor: "#ffffff", color: "#5C5C5C" }}>
            <Row>
              <Col md={8}>
                <AccentHeader>                  
                  <h2>The Results</h2>
                </AccentHeader>
                <SectionBody size="regular">
                  When the digital campaign launched in Q2 2022, the HCP site experienced a significant influx of traffic, with paid social accounting for 79% of site visits. Paid search showed 50% scroll, and nonbounce rates increased +33% and +37%, respectively.<br/><br/>

                  With engaging digital content and the additional support of print tactics and HCP education efforts to drive brand awareness, PLUVICTO has been established as the first and only targeted radioligand therapy to significantly improve survival in heavily treated patients with mCRPC.
                </SectionBody>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>        

        <SectionContainer  noTopMargin noBottomMargin>
          <Container>
            <Row>
              <Col md={8}>
                <hr/>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <Container style={{ paddingBottom: "60px" }}>
            <Row>
              <Col md={8}>                
                <SectionBody size="small">
                  CGI, computer-generated imagery; DNA, deoxyribonucleic acid; HCP, health care provider; mCRPC, metastatic castration-resistant prostate cancer; MOA, mechanism of action; PET, positron emission tomography; PSMA, prostate-specific membrane antigen; PSMA+, PSMA positive.
                </SectionBody>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>


      </>
    </ThemeProvider>
  </Layout>
)

export default pluvictoProfessionalDigital

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "2023/pluvicto2/image1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image2: file(relativePath: { eq: "2023/pluvicto2/image2.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    videoThumbnail1: file(relativePath: { eq: "2023/pluvicto2/thumbnail1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    videoThumbnail2: file(relativePath: { eq: "2023/pluvicto2/thumbnail2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }   
    videoThumbnail3: file(relativePath: { eq: "2023/pluvicto2/thumbnail3.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }    
  }
`
